export const permissionsMenu = {
    '/': { menu: 'dashboard' },
    '/casafari': { menu: 'casafari' },
    '/groagenda': { menu: 'groagenda' },
    '/documentos/beneficios': { menu: 'documents_offline', pos: 'benefits' },
    '/documentos/plan-carrera': { menu: 'documents_offline', pos: 'career_plan' },
    '/documentos/codigo-vestimenta': { menu: 'documents_offline', pos: 'dress_code' },
    '/documentos/plan-formacion': { menu: 'documents_offline', pos: 'training_plan' },
    '/staff/rrhh': { menu: 'staff', pos: 'rrhh' },
    '/staff/legal': { menu: 'staff', pos: 'legal' },
    '/staff/marketing-design': { menu: 'staff', pos: 'marketing-design' },
    '/staff/office-image': { menu: 'staff', pos: 'office-image' },
    '/staff/it-crm': { menu: 'staff', pos: 'it-crm' },
    '/staff/formation': { menu: 'staff', pos: 'formation' },
    '/staff/mentoring': { menu: 'staff', pos: 'mentoring' },
    '/mis-herramientas/contenido-rrss': { menu: 'my_tools', pos: 'social_media_content' },
    '/mis-herramientas/portfolio': { menu: 'my_tools', pos: 'porfolio' },
    '/mis-herramientas/documentos-interes': { menu: 'my_tools', pos: 'documents_interest' },
    '/mis-herramientas/cartas-clientes': { menu: 'my_tools', pos: 'cartas_clientes' },
    '/grogames/grochampions': { menu: 'grogames', pos: 'champions' },
    '/grogames/leagues': { menu: 'grogames', pos: 'leagues' },
    '/grogames/catalogo-premios': { menu: 'grogames', pos: 'awards_catalog' },
    '/leads': { menu: 'leads' },
    '/contactos-sede': { menu: 'contacts_headquarters' },
    '/galeria-fotos': { menu: 'photos_gallery' },
    '/profile': { menu: 'users', pos: 'users.profile' },
    '/dispatcher/equipos-hipotecas': { menu: 'dispatcher', pos: 'dispatcher.mortgages.teams' },
    '/dispatcher/asesores-hipotecas': { menu: 'dispatcher', pos: 'dispatcher.mortgages.users' },
    '/dispatcher/equipos-inmuebles': { menu: 'dispatcher', pos: 'dispatcher.properties.teams' },
    '/dispatcher/asesores-inmuebles': { menu: 'dispatcher', pos: 'dispatcher.properties.users' },

}
