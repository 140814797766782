import React from 'react'
import Groinversia from '../../../components/svg/Groinversia'

const GroinversiaComponent = () => {
    return (
        <>
            <Groinversia />
            <h2 className='top'>Invierte en el sector inmobiliario en un click gracias a Groinversia.</h2>
            <h2 className='top bottom'>Sé el primero en invertir en esta promotora inmobiliaria del grupo Grocasa, conociendo antes que nadie las oportunidades de inversión.</h2>
            <h2 className='bottom'>¿Quieres más información? Escríbenos;</h2>
            <a href='https://groinversia.com/' target='_blank' rel='noopener noreferrer'>www.groinversia.com</a>
        </>
    )
}

export default GroinversiaComponent