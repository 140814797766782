import styled from "styled-components";

export const StyledCard = styled.div`
  position: relative;
  width: ${({ fullWeight }) => (fullWeight ? "100% " : "400px")};
  margin: ${({ margin }) => (margin ? "120px 0px 0px 0px" : "0px")};
  box-sizing: border-box;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #e9e9e9;
  height: ${({ height }) => (height ? "fit-content" : "auto")};
  background-image: url(${({ background }) => background});
  background-repeat: no-repeat;    
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledCardActions = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin: 0 20px 20px;

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }

`
export const StyledCardHeader = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: -1px;
  padding: 10px 20px;
  color: rgba(0, 0, 0, 0.85);
  border-radius: 2px 2px 0 0;    

  &.modal-header {
    justify-content: space-between;
    background: #f5f5f5;
    align-items: center;
  }
  
  .card-img {
    //width: 280px;
    height: auto;
    max-width: 100%;
  }
  h1 {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  }
  .card-league-header-container {
    display: flex;
    align-items: center;
    span {
      margin-left: 10px;
      p {
        margin: 0;
      }
    }
  }
`;

export const StyledCardBody = styled.div`
  padding: 20px;
  max-height: 700px;
  overflow-x: auto;
`;
