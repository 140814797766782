import React from 'react'
import { Link } from "react-router-dom";
//Components
import Button from '../../components/ui/button'
import ShowInfoGroLeagues from './ShowInfoGroLeagues'
//hooks
import { useModal } from '../../hooks/context/modal'
//styles
import { StyledLastColumn } from './styles'
import { backgroundOffices } from '../../styles/variables'

const textContent = `
  <h2>
    ¿Tienes un Groamigo?
  </h2>
  <h2>
      Haznos llegar su cv a través de este <a href="https://empleo.grocasa.com/jobs/trabaja-con-nosotros-3db1bfe8-3daf-48a5-8bc9-e6e0ff3efd6d?utm_source=groamigo&utm_medium=groamigo&utm_campaign=groamigo" target="_blank">link</a>. <b>Si finalmente entra a formar parte de Grocasa recibirás 300€ 
      en un cheque de Amazon cuando realice su primera facturación</b> y así poder celebrar que le habrás conseguido 
      una carrera profesional a tu Groamigo.
  </h2>
  <h2>
    ¡Así da gusto tener amigos!
  </h2>`


const CardFriendChampion = () => {
    const { setOpen, setComponent } = useModal()

    return (
        <StyledLastColumn>
            <div>
                <div className="">
                    <h2 style={{ marginBottom: 20 }}>Recomienda un <b>#GROAMIGO</b> y llévate <b>300€</b></h2>
                    <h2 className='margin-vertical'>Contacta con <br /><a href='mailto:rrhh@grocasa.com'>rrhh@grocasa.com</a></h2>
                </div>
                <div>
                    <Button
                        styles={'card-gro'}
                        action={() => {
                            setComponent(
                                <ShowInfoGroLeagues
                                    img='/static/leagues/gro-friends.webp'
                                    content={textContent}
                                    textButton='Presenta un Groamigo'
                                    link={process.env.REACT_APP_GROAMIGO}
                                    externalLink
                                    background={backgroundOffices}
                                />
                            );
                            setOpen(true)
                        }}
                    >
                        Saber más
                    </Button>
                </div>
                <div>
                    <img src="/static/leagues/gro-friends.webp" alt="grofriends" />
                </div>
            </div>
            <div className="gro-champions">
                <div>
                    <img src="/static/leagues/gro-champions.webp" alt="grochampions" />
                </div>
                <div>
                    <h2 className="center">La competición de oficinas más exigente</h2>
                </div>
                <div>
                    <Link to="/grogames/grochampions">
                        <Button styles={'card-gro'}>
                            Saber más
                        </Button>
                    </Link>
                </div>
            </div>
        </StyledLastColumn>
    )
}

export default CardFriendChampion