import React from 'react'
import PdfSvg from '../svg/PdfSvg'
import Excel from '../icons/Excel'
import EyeSvg from '../svg/EyeSvg'
import Word from '../icons/Word'
import Mp4Svg from '../svg/Mp4Svg'
import DownLoadSvg from '../svg/DownLoadSvg'
import ImgIcon from '../icons/ImgIcon'
import styled from 'styled-components'

const StyledContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    background: #FFF;
    padding: 20px;    
    cursor: pointer;

    > div {
        display: flex;
        align-items: center;
        gap: 20px;

        p {
            color: #35374A;
            margin: 0;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        &:nth-child(2){
            svg {
                path {
                    stroke: #ffffff;
                }
            }
        }       
    }    

    &:hover {
        border: 1px solid #FF671B;

        > div:nth-child(2) {
            svg {
                path {
                    stroke: #FF671B;
                }
            }
        }
    }
`

const CardDocuments = ({ data, actionShow }) => {

    const typeIcon = (type) => {
        let options = {
            pdf: <PdfSvg />,
            excel: <Excel />,
            word: <Word />,
            mp4: <Mp4Svg />,
            img: <ImgIcon />
        }

        return options[type]
    }

    const actionDownload = () => {
        if (data.type === 'pdf' || data.type === 'mp4' || data.type === 'img') {
            return true
        }
        return false
    }

    return (
        <StyledContainer>
            <div>
                <div>
                    {typeIcon(data.type)}
                </div>
                <p>{data.label}</p>
            </div>
            <div>
                {
                    actionDownload() && (
                        actionShow ?
                            <a href='#' onClick={() => actionShow(data.route, data.type)}>
                                <EyeSvg />
                            </a>
                            :
                            <a href={data.route} rel="noopener" target='_blank' >
                                <EyeSvg />
                            </a>
                    )
                }
                <a href={data.route} download>
                    <DownLoadSvg />
                </a>
            </div>
        </StyledContainer>
    )
}

export default CardDocuments