import React, { useState, useEffect } from "react";
import { useAlert } from "../../hooks/context/alert";

//components
import Title from "../../components/ui/title";
import Loading from "../../components/loading";
import Input from "../../components/ui/input";
import Card from "../../components/card";

//styles
import { StyledContainer, StyledInputContent } from "./styles";
import NotData from "../../components/notData";
import {
  getApiDispatcher,
  updateApiAlgorithms,
  updateApiDispatcherTeam,
} from "../../actions";

function Teams({ type, typeName }) {
  const [teams, setTeams] = useState([]);
  const [algorithms, setAlgorithms] = useState([]);
  const { openAlert } = useAlert();
  const [loading, setLoading] = useState(false);

  const getTeams = async () => {
    setLoading(true);
    const data = await getApiDispatcher({ type, option: "teams" });
    setTeams(data.teams);
    setAlgorithms(data.algorithms);
    setLoading(false);
  };

  const update = async (work_pressure, id) => {
    setLoading(true);
    await updateApiDispatcherTeam({ type, id, work_pressure }, openAlert);
    setLoading(false);
  };

  const updateAlgorithms = async (e) => {
    setLoading(true);
    const id = e.target.value;
    updateApiAlgorithms({ active: true, id, type }, openAlert);
    setLoading(false);
  };

  useEffect(() => {
    getTeams();
  }, [type]);

  return (
    <>
      <Title text={`Equipos ${typeName} online`} />
      {loading && <Loading />}
      {teams.length <= 0 ? (
        <NotData />
      ) : (
        <StyledContainer>
          <Card title="Equipo" fullWeight>
            {teams.map((item, index) => (
              <ChildComponent
                key={index}
                name={item.name}
                work={item.work_pressure}
                update={update}
                id={item.id}
              />
            ))}
          </Card>
          {algorithms && algorithms.length > 0 && (
            <Card title="Algoritmo" fullWeight>
              <StyledInputContent className="ag">
                <label>Algoritmo</label>
                <select onChange={updateAlgorithms}>
                  {algorithms.map((item, index) => {
                    return item.active ? (
                      <option key={index} value={item.id} selected>
                        {item.name}
                      </option>
                    ) : (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </StyledInputContent>
            </Card>
          )}
        </StyledContainer>
      )}
    </>
  );
}

export default Teams;

const ChildComponent = ({ name, id, work, update }) => {
  const [workPressure, setWorkPressure] = useState(work);
  const updateInput = () => {
    if (workPressure) {
      update(workPressure, id);
    }
  };

  const handleOnchange = (e) => {
    setWorkPressure(e.target.value);
  };

  return (
    <StyledInputContent>
      <label>{name}</label>
      <Input
        type="number"
        value={workPressure}
        onChange={handleOnchange}
        onBlur={updateInput}
      />
    </StyledInputContent>
  );
};
