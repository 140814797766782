import React, { useEffect, useState } from "react";
import classNames from "classnames";

import { ratingTeam, ratingUser } from "../../actions";

//components
import Card from "../../components/card";
// import Filters from "./Filters";

//styles
import { StyledRatingContainer } from "./styles";

const formatPoints = (value) => {
  const data = value.split(",");
  if (data[1]) {
    return data[1].length >= 2 ? value : `${data[0]},${data[1]}0`;
  }
  return data[0] + ",00";
};

const Rating = ({ permitions }) => {
  const dateObj = new Date();
  const year = dateObj.getUTCFullYear();
  const [fullData, setFullData] = useState(year);
  const [fullDataText, setFullDataText] = useState(year);
  const [loading, setLoading] = useState(false);

  function handleOnchange(value, valueText) {
    setFullData(value);
    setFullDataText(valueText);
  }

  return (
    <StyledRatingContainer>
      {/* <Filters
        saveFilter={handleOnchange}
        yearSelect={fullData}
        loading={loading}
      /> */}
      <div className="rating-data-container">
        {permitions.length > 0 && (
          <>
            {permitions.map((item, index) => {
              return (
                <LoadData
                  key={index}
                  type={item}
                  fullData={fullData}
                  fullDataText={fullDataText}
                  setLoadingParent={setLoading}
                />
              );
            })}
          </>
        )}
      </div>
    </StyledRatingContainer>
  );
};

const LoadData = ({ type, fullData, fullDataText, setLoadingParent }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  async function getTeams() {
    setLoading(true);
    setLoadingParent(true);
    const res = await ratingTeam(fullData);
    if (res) {
      if (res.meta.success) {
        setData(res.data.teams);
      }
    }
    setLoading(false);
  }

  async function getUsers() {
    setLoading(true);
    const res = await ratingUser(fullData);
    if (res) {
      if (res.meta.success) {
        setData(res.data.users);
      }
    }
    setLoadingParent(false);
    setLoading(false);
  }
  useEffect(() => {
    if (type === "properties.offline.teams") {
      getTeams();
    } else {
      getUsers();
    }
  }, [type, fullData]);

  function activeUserOrTeam(value) {
    if (type === "properties.offline.teams") {
      return value.user_team_position;
    } else {
      return value.user_position;
    }
  }

  return (
    <>
      <Card
        img={
          type === "properties.offline.teams"
            ? `/static/leagues/teams.webp`
            : `/static/leagues/users.webp`
        }
        background={
          type === "properties.offline.teams"
            ? `/static/leagues/background-teams.webp`
            : `/static/leagues/background-users.webp`
        }
        fullWeight
      >
        <div className="rating-card-container">
          {loading ? (
            <Loading />
          ) : data.length > 0 ? (
            data.map((item, index) => {
              return (
                <div key={index} className="rating-card-content">
                  <div
                    className={classNames("rating-card-body-content", {
                      'position user-color': activeUserOrTeam(item)
                    })}
                  >
                    <div className="text-content">
                      <div>
                        <div>
                          <h1>{item.position}</h1>
                        </div>
                        <div>
                          <img
                            src={
                              type === "properties.offline.teams"
                                ? item.logo
                                : item.avatar
                            }
                            className="avatar"
                            alt="avatar"
                          />
                        </div>
                        <div>
                          <span>
                            <h2>{item.name}</h2>
                            <p
                              style={type === "properties.offline.teams" ? { fontWeight: 'bold', fontSize: 14 } : {}}
                            >
                              {item.agency_name}
                            </p>
                          </span>
                        </div>
                      </div>
                      <h3>
                        {formatPoints(
                          type === "properties.offline.teams"
                            ? item.points
                            : item.billed
                        )}
                      </h3>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <h3 className="not-data">No hay datos</h3>
          )}
        </div>
      </Card>
    </>
  );
};

const Loading = () => {
  return (
    <svg
      width="100px"
      height="100px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle cx="84" cy="50" r="10" fill="#f3f3f3">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="0.5952380952380952s"
          calcMode="spline"
          keyTimes="0;1"
          values="10;0"
          keySplines="0 0.5 0.5 1"
          begin="0s"
        ></animate>
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2.380952380952381s"
          calcMode="discrete"
          keyTimes="0;0.25;0.5;0.75;1"
          values="#f3f3f3;#a3a3ac;#b8babd;#d8dddf;#f3f3f3"
          begin="0s"
        ></animate>
      </circle>
      <circle cx="16" cy="50" r="10" fill="#f3f3f3">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="2.380952380952381s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="0s"
        ></animate>
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="2.380952380952381s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="0s"
        ></animate>
      </circle>
      <circle cx="50" cy="50" r="10" fill="#d8dddf">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="2.380952380952381s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.5952380952380952s"
        ></animate>
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="2.380952380952381s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.5952380952380952s"
        ></animate>
      </circle>
      <circle cx="84" cy="50" r="10" fill="#b8babd">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="2.380952380952381s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1.1904761904761905s"
        ></animate>
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="2.380952380952381s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1.1904761904761905s"
        ></animate>
      </circle>
      <circle cx="16" cy="50" r="10" fill="#a3a3ac">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="2.380952380952381s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1.7857142857142856s"
        ></animate>
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="2.380952380952381s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1.7857142857142856s"
        ></animate>
      </circle>
    </svg>
  );
};
export default Rating;
