import React from 'react'
import Santander from '../../../components/svg/Santander'
import Grocasa from '../../../components/svg/Grocasa'
import ButtonLink from '../../../components/ui/ButtonLink'

const BenefitsPayroll = () => {
    return (
        <>
            <div className='icons bottom'>
                <div><Santander /></div>
                <div><Grocasa /></div>
            </div>
            <h2>
                Disfruta de los múltiples beneficios que tenemos para nuestro equipo.
            </h2>
            <h2 className='top'>¿Quieres más información? Escríbenos;</h2>
            <a href='mailto:adminhipotecas@grocasa.com'>adminhipotecas@grocasa.com</a>
            <div className='top'>
            <ButtonLink
                href='https://drive.google.com/file/d/1fNH5usVKGWKd4xapvlZnRgamQMbPCa1g/view?usp=drive_link'
                target='_blank'
            >
                Haz click para saber más
            </ButtonLink>
            </div>
        </>
    )
}

export default BenefitsPayroll