import React from 'react'

const Groslidarios = () => {
    return (
        <>
            <h2>
                Como empresa comprometida con nuestra comunidad, respaldamos causas sociales que
                mejoran la vida de nuestros vecinos.
            </h2>
            <h2 className='top bottom'>
                Anualmente colaboramos con diferentes fundaciones, entre las que está la Cruz Roja.
            </h2>
            <h2 className='bottom'>
                Nuestro objetivo es aportar nuestro granito de arena para mejorar la calidad de vida de nuestra comunidad.
            </h2>
            <h2>¿Quieres más información? Escríbenos;</h2>
            <a href='mailto:rrhh@grocasa.com'>rrhh@grocasa.com</a>
        </>
    )
}

export default Groslidarios