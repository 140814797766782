import React from 'react'
import styled from 'styled-components'
import { pColor } from '../../../styles/variables'

const StyledContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;  

  > div { 
      display: flex;
      flex-direction: column;           
      padding: 20px;

      > h2 {    
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        margin: 0;

        &:first-child {
          margin-bottom: 20px;
        }   
      }        

      &:last-child {
          background-color: #F2F2F2;                
      }            
  }

  .schedule-week {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;            

      h2 {
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
          margin: 0;
          color: ${pColor};

          &.small {
              font-size: 12px;
              line-height: 17px;
          }
      }
  }

  .schedule {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
      margin-top: 15px;
  }

  @media (max-width: 1180px) {
      grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 600px) {
      .schedule-week {
          grid-template-columns: repeat(1, 1fr);

          > div:first-child {
              margin-bottom: 20px;
          }
      }
  }  
`

const Schedule = () => {
  return (
    <StyledContent>      
        <div>
          <h2>
            Estiramos los fines se semana, porque lo que es importante para ti, también lo es para nosotros.
          </h2>
          <h2>
            Además, hemos establecido un horario especial para las fechas señaladas: Navidad 25/15 y
            Fin de Año (31/12) para que puedas disfrutar de los que más quieres.
          </h2>
        </div>
        <div>
          <h2>De manera alterna cada semana tiene un horario distinto:</h2>
          <div className='schedule-week'>
            <div>
              <h2><b>A</b> Semana Corta</h2>
              <div className='schedule top'>
                <div>
                  <h2 className='small'>Lunes a Viernes</h2>
                  <h2 className='small'>Sábado</h2>
                </div>
                <div>
                  <h2 className='small'>9:30 a 20h</h2>
                  <h2 className='small'>Libre</h2>
                </div>
              </div>
            </div>
            <div>
              <h2><b>B</b> Semana Larga</h2>
              <div className='schedule top'>
                <div>
                  <h2 className='small'>Lunes a Viernes</h2>
                  <h2 className='small'>Sábado</h2>
                </div>
                <div>
                  <h2 className='small'>9:30 a 20h</h2>
                  <h2 className='small'>9:30 a 14h</h2>
                </div>
              </div>
            </div>
          </div>
        </div>      
    </StyledContent>
  )
}

export default Schedule