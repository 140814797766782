import React, { useState } from "react";
import { Link } from "react-router-dom";

//context
import { UseUser } from '../../hooks/context/user'

//components
import AlertError from "../../components/alert/AlertError";
import AlertSuccess from "../../components/alert/AlertSuccess";
import Loading from "../../components/loading";
import Form from "./form";

import { messages } from "../../libs/helpers/message";

//styles
import {
  StyledContainer,
  StyledContent,
  StyledHeader,
} from "./styled";

export default function Login({ history, load, location }) {
  const [ user, setUser ] = useState({ email: '', password: '', repeatPassword: ''})
  const [ loading, setLoading ] = useState(load === undefined ? false : true)
  const [ error, setError ] = useState({ email: false, password: false, repeatPassword: false})
  const [ errorServer, setErrorServer ] = useState(false)
  const [ resetPasswordSuccess, setResetPasswordSuccess ] = useState(location.state !== undefined ? location.state.success : false)
  const path = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  const { signIn } = UseUser()

  const handleOnchange = (e) => {
    const { value, name } = e.target
    setUser( { ...user,[name]: value } )
  }

  const validate = () => {
    if(!path.test(user.email) || user.email === ''){
      setError( { ...error,['email']: true, ['password']: false } )
      return false
    } else if(user.password === '') {
      setError( { ...error,['password']: true, ['email']: false } )
      return false
    }
    return true
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if(validate()) {
      setLoading(true)
      const result = await signIn({email: user.email, password: user.password})
      if(!result) {
        setErrorServer(true)
      } else {
        history.push("/")
      }
      setLoading(false)
    }
  }

  return (
    <StyledContainer>
      { loading && <Loading />}
      <StyledContent>
        <div className="login-media">
          <StyledHeader>
            <Link to="/">
               <img src="/static/Grocasa500.png" />
            </Link>
          </StyledHeader>
          {errorServer &&  <AlertError text={messages.ERROR_SERVER_LOGIN} />}
          {error.email && <AlertError text={messages.INCORRECT_FORMAT} />}
          {resetPasswordSuccess && <AlertSuccess text={messages.RESET_PASSWORD_SUCCESS} />}
          <Form 
              user={user}
              error={error}
              handleSubmit={handleSubmit}
              handleOnchange={handleOnchange}
            />
        </div>
      </StyledContent>
    </StyledContainer>
  );
}
