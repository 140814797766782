import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

//context
import { UseUser } from '../../hooks/context/user'

//components
import AlertError from "../../components/alert/AlertError";
import Loading from "../../components/loading";
import Form from "./form";

import { messages } from "../../libs/helpers/message";

//styles
import {
  StyledContainer,
  StyledContent,
  StyledHeader,
} from "../login/styled";

export default function ResetPassword({ match, type, history, load }) {
  const [user, setUser] = useState({ password: '', repeatPassword: '' })
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState({ password: false, repeatPassword: false, message: '' })
  const [errorResponse, setErrorResponse] = useState({ status: false, error: '' })
  const { resetPasswordCheck, resetPassword } = UseUser()

  const handleOnchange = (e) => {
    const { value, name } = e.target
    setUser({ ...user, [name]: value })
    setError({ ...error, [name]: false })
  }

  const validate = () => {
    const { password, repeatPassword } = user

    if (!password) {
      setError({ ...error, password: true, message: 'Debe introducir la contraseña' })
      return false
    }
    if (password && password.length < 8) {
      setError({ ...error, password: true, message: 'La contraseña debe tener al menos 8 caracteres' })
      return false
    }
    if (!repeatPassword) {
      setError({ ...error, repeatPassword: true, message: 'Debe repetir la contraseña' })
      return false
    }
    if (repeatPassword && (repeatPassword !== password)) {
      setError({ ...error, repeatPassword: true, message: 'Las contraseñas no coinciden' })
      return false
    }

    return true;
  }

  const changePasswor = (e) => {
    e.preventDefault()
    const { password } = user
    const isValid = validate();
    if (isValid) {
      setError({ password: false, repeatPassword: false, message: '' })
      setLoading(true)
      resetPassword(match.params.token, password).then(res => {
        setLoading(true)
        if (res.data.meta.success) {
          history.push(
            {
              pathname: '/login',
              state: { success: true }
            }
          )
        } else {
          setErrorResponse({
            status: true,
            data: res.data.meta.errors[0]
          })
        }
      })
    }
  }

  useEffect(() => {
    if (match.params.token) {
      resetPasswordCheck(match.params.token).then(res => {
        if (!res.data.meta.success) {
          history.push(
            {
              pathname: '/recovery_password',
              state: { errorToken: true }
            }
          )
        }
        setLoading(false)
      })
    }
  }, [type])

  return (
    <StyledContainer>
      {loading && <Loading />}
      <StyledContent>
        <div className="login-media">
          <StyledHeader>
            <Link to="/">
              <img src="/static/Grocasa500.png" />
            </Link>
          </StyledHeader>          
          {error.message && <AlertError text={error.message} />}
          {errorResponse.status && <AlertError text={errorResponse.data} />}
          {
            !loading && <Form
              password={user.password}
              repeatPassword={user.repeatPassword}
              error={error}
              handleSubmit={changePasswor}
              handleOnchange={handleOnchange}
            />
          }
        </div>
      </StyledContent>
    </StyledContainer>
  );
}
