import React from 'react'
//DATA
import { DATA } from '../../components/staff/data'
//COMPONENTS
import Layout from '../../components/layout'
import Title from '../../components/ui/title'
import Documents from '../../components/staff/Documents'
import ReactPlayer from 'react-player'
//HOOKS
import { useModal } from '../../hooks/context/modal'
//STYLES
import styled from 'styled-components'

const StyledVideo = styled.div`
    position: relative;
    padding-top: 56.25%; 

    .play {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }    
    
    .react-player {
        position: absolute;
        top: 0;
        left: 0;
    }

    @media (max-width: 600px) {
        padding-top: 85.25%; 
    }
`

const ContentRRSS = () => {
    const { setOpen, setComponent } = useModal()
    const data = DATA['contentRrss']

    const selectComponent = (url, type) => {
        if (type === 'mp4') {
            return (
                <StyledVideo data-cy="video-header-desktop-mortgage-assistant-0000">
                    <ReactPlayer
                        url={url}
                        controls={true}
                        playing={true}
                        muted
                        width='100%'
                        height='100%'
                        className="react-player"
                        loop={true}
                    />
                </StyledVideo>
            )
        } else {
            return (
                <div style={{ margin: '35px' }}>
                    <img src={url} alt="media" style={{ maxWidth: '100%' }} />
                </div>
            )
        }
    }

    const actionShow = (url, type) => {
        setComponent(selectComponent(url, type))
        setOpen(true)
    }

    return (
        <Layout>
            <Title text={<span><b>Contenido de RRSS</b></span>} />
            <Documents data={data.documents} notTitle actionShow={actionShow} />
            <style>
                {
                    `
                        .form-close-modal > svg {
                            color: #FF671B !important;
                        }
                    `
                }
            </style>
        </Layout>
    )
}

export default ContentRRSS