import styled from "styled-components";
import { OpenInNew } from "@styled-icons/material-rounded/OpenInNew";
import { ChevronBottom } from "@styled-icons/open-iconic/ChevronBottom";
import { UserCircle } from "@styled-icons/boxicons-regular/UserCircle";
import { BarChartLine } from "@styled-icons/bootstrap/BarChartLine";
//import { pColor, sColor } from "../../styles/variables";
import { Link } from "react-router-dom";
const colorMenuLink = `#35374A`;
const colorMenuLinkFranchisee = `#ffffff`;
const colorSubMenuLInksAndIcons = '#FF671B';
const colorSubMenuLInksAndIconsFranchisee = '#C79D46';

const iconMenuCommon = `
  width: 19px;
  height: 19px;
  cursor: pointer;
`
const iconsMenu = `
  ${iconMenuCommon}
  color: ${colorSubMenuLInksAndIcons};    
`;

const iconsMenuFranchisee = `
  ${iconMenuCommon}
  color: ${colorSubMenuLInksAndIconsFranchisee};    
`;

export const StyledContainer = styled.div`
  position: fixed;
  height: 100%;
  z-index: 50;
  overflow: auto;
  background-color: ${({ franchisee }) => franchisee ? '#35374A' : '#F5F5F5'};  
  //border: 1px solid #ffffff;
  box-shadow: rgba(0, 0, 0, 0.10) 2px 0px 4px;
  transition: all 0.2s;

  ::-webkit-scrollbar {
    width: 5px;                
  }              
              
  ::-webkit-scrollbar-track {
      background: #f5f5f5;             
  }               
    
  ::-webkit-scrollbar-thumb {
      background: #888; 
  }              
    
  ::-webkit-scrollbar-thumb:hover {
      background: #555; 
  }  

  &.desktop-menu-open {
    max-width: 225px;
    min-width: 225px;
    width: 225px;
  }
  &.desktop-menu-close {
    max-width: 75px;
    min-width: 75px;
    width: 75px;
    .submenu {
      margin: 0;
      padding: 0;

      li {
        padding: 0 16px;
      }
    }
  }
  &.phone-menu-close {
    max-width: 0px;
    min-width: 0px;
    width: 0px;
  }
  &.phone-menu-open {
    max-width: 223px;
    min-width: 223px;
    width: 223px;
  }
`;

export const StyledLinkLogo = styled(Link)`
  background-color: ${({ franchisee }) => franchisee ? colorMenuLink : '#F5F5F5'};
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  line-height: 50px;
  transition: min-width 0.5s;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 30px 0;
  background: transparent;
  li {
    position: relative;
    color: #fff;
    background-color: transparent;
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    margin-bottom: 0;
    font-family: "Montserrat", sans-serif;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    &.active > a > span {
      color: ${colorSubMenuLInksAndIcons};
      font-weight: 700;
    }     
    &.activeButton > button > span {
      color: ${colorSubMenuLInksAndIcons};
      font-weight: 700;
    }     
    &.minimize {
      button {
        width: 70px;
        display: flex;
        justify-content: center;
        span {
          display: none;
        }
      }
    }
    &.active > button > span {
      font-weight: 700;      
    }
    .submenu {
      list-style: none;
      background-color: ${({ franchisee }) => franchisee ? '#8185A3' : '#EDEDED'};
      box-shadow: none;
      overflow: hidden;
      max-height: 0;
      padding: 0;
      transition: max-height 0.35s;
      &.nivel-two {
        padding-left: 20px;
      }       
      a {
        padding: ${({ menuClose }) => menuClose ? '0' : '0 0 0 40px'};
        text-decoration: none;
        span {
          margin: 0;
          color: ${({ franchisee }) => franchisee ? colorMenuLink : colorSubMenuLInksAndIcons};
        }                 
      }
      button {
        padding-left: 0;
        span {
          color: ${({ franchisee }) => franchisee ? colorMenuLink : colorSubMenuLInksAndIcons};
        }
      }
      &.open {
        overflow: hidden;
        max-height: 800px;
        height: auto;
        transition: max-height 0.35s;
      }           
    }
    &:hover {
      background: ${({ franchisee }) => franchisee ? '#525573' : '#E1E0E0'};;
    }    
  }  
`;
export const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: 400;
  width: auto;
  display: flex;
  align-items: center;
  padding-left: 15px;
  height: 40px;
  span {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
    color: ${colorSubMenuLInksAndIcons};
  }
`;
export const StyledLinkCustom = styled.a`
  text-decoration: none !important;
  font-weight: 400;
  width: auto;
  display: flex;
  align-items: center;
  padding-left: ${({ menuClose }) => menuClose ? '25px' : '15px'};
  height: 40px;
  span {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
    color: ${({ franchisee }) => franchisee ? colorMenuLinkFranchisee : colorMenuLink};
  }
`;
export const StyledBotton = styled.button`
  width: 100%;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding-left: 15px;
  height: 40px;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 14px;  
  color: ${({ franchisee }) => franchisee ? colorMenuLinkFranchisee : colorMenuLink};
  cursor: pointer;
  svg {
    margin-right: 10px;
  }
`;

export const IconDispatcher = styled(OpenInNew)`
  ${({ franchisee }) => franchisee ? iconsMenuFranchisee : iconsMenu}
`;

export const IconRatings = styled(BarChartLine)`
  ${({ franchisee }) => franchisee ? iconsMenuFranchisee : iconsMenu}
`;

export const IconCandict = styled(UserCircle)`  
  ${({ franchisee }) => franchisee ? iconsMenuFranchisee : iconsMenu}
`;

export const IconOpen = styled(ChevronBottom)`
  position: absolute;
  right: 15px;
  top: 17px;
  color: ${({ franchisee }) => franchisee ? iconsMenuFranchisee : iconsMenu};
  width: 10px;
  height: 10px;
`;
