import React from 'react'

const BuyHousing = () => {
    return (
        <>
            <h2>
                Para ti tenemos las mejores condiciones hipotecarias gracias a 
                los acuerdos exclusivos que tiene Grocasa Hipotecas con diferentes 
                entidades financieras para que compres tu casa con las mejores condiciones.
            </h2>
        </>
    )
}

export default BuyHousing