import React from 'react'
import { Link } from "react-router-dom";
//COMPONENTS
import Button from '../../../components/ui/button'

const ManyPrizes = () => {
    return (
        <>
            <h2>
                Tenemos un sistema de recompensas, con premios trimestrales, semestrales y anuales para los/as mejores asesores/as.
            </h2>
            <div className='top'>
                <Link to='/grogames/catalogo-premios'>
                    <Button>
                        Catálogo de premios
                    </Button>
                </Link>
            </div>
        </>
    )
}

export default ManyPrizes