import React from 'react'
//COMPONENTS
import CareerPlan from './CareerPlan';
import TrainingPlan from './TrainingPlan';
import EventsBusiness from './EventsBusiness';
import Entrepreneurship from './Entrepreneurship';
import BuyHousing from './BuyHousing';
import ManyPrizes from './ManyPrizes';
import BenefitsPayroll from './BenefitsPayroll';
import GroinversiaComponent from './GroinversiaComponent';
import Groslidarios from './Groslidarios';

//STYLES
import styled from 'styled-components'

const StyledContent = styled.div`
    background-image: url(${({ background }) => background});
    background-repeat: no-repeat;    
    background-position: center center;
    background-size: cover;        

    .content {
        display: flex;
        flex-direction: column;        
        justify-content: center;
        padding: 20px;
        max-width: 50%;
        min-height: 275px;

        h2, a {
            font-size: 12px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            margin: 0;
            color: #35374A;

            &.top {
                margin-top: 10px;
            }
            &.bottom {
                margin-bottom: 10px;
            }            
        }  

        a {
            text-decoration: underline;
        }



        > div {
            display: flex;
            flex-direction: column;                                            

            &.top {
                margin-top: 30px;
            }
            &.bottom {
                margin-bottom: 30px;
            }

            &.icons {
                flex-direction: row;
                align-items: center;

                > div:first-child {
                    margin-right: 20px;
                }
            }

            a {
                color: #ffffff;
                text-decoration: none;
            }
        }     

        @media (max-width: 768px) {
            max-width: 100%;
            justify-content: flex-start;
            button {
                width: 100%;
            }
        }

        @media (max-width: 600px) {
            > div {
                &.icons {
                    flex-direction: column;      
                    align-items: start;             
    
                    > div:first-child {
                        margin-right: 0;
                        margin-bottom: 10px;
                    }                   
                }
                a {
                    width: 100%;
                }
            }
        }
    }

    @media (max-width: 768px) {
        background-image: url(${({ backgroundTablet }) => backgroundTablet});

        .content {
            max-width: 100%;
        }
    }

    @media (max-width: 600px) {
        background-image: url(${({ backgroundMobile }) => backgroundMobile});
    }
`

const renderComponent = (component) => {
    let options = {
        careerPlan: <CareerPlan />,
        trainingPlan: <TrainingPlan />,
        eventsBusiness: <EventsBusiness />,
        entrepreneurship: <Entrepreneurship />,
        buyHousing: <BuyHousing />,
        manyPrizes: <ManyPrizes />,
        benefitsPayroll: <BenefitsPayroll />,
        groinversia: <GroinversiaComponent />,
        grosolidarios: <Groslidarios />
    }

    return options[component]
}


const CardCommon = ({ background, backgroundTablet, backgroundMobile, child }) => {
    return (
        <StyledContent
            background={background}
            backgroundTablet={backgroundTablet}
            backgroundMobile={backgroundMobile}
        >
            <div className='content'>
                {renderComponent(child)}
            </div>
        </StyledContent>
    )
}

export default CardCommon