import React from 'react'
import { Link } from "react-router-dom";
//COMPONENTS
import Button from '../../../components/ui/button'

const EventsBusiness = () => {
    return (
        <>
            <h2>
                Compartimos juntos momentos únicos.
            </h2>
            <h2 className='top bottom'>
                Hemos pensando diferentes eventos a lo largo del año
                para que disfrutes, te relajes y compartas experiencias
                y momentos increíbles con tus compañeros.
            </h2>
            <div className='top'>
                <Link to='/'>
                    <Button>
                        Galería fotográfica
                    </Button>
                </Link>
            </div>
        </>
    )
}

export default EventsBusiness