import React, { useEffect, useState } from 'react'
//ACTIONS
import { galleryPhotosAllAction, galleryPhotosSlugAction } from '../../actions';
import { useAlert } from '../../hooks/context/alert';
//COMPONENTS
import Layout from '../../components/layout'
import Title from '../../components/ui/title'
import Loading from '../../components/loading';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
//STYLES
import styled from "styled-components";
import { pColor } from '../../styles/variables';

const StyledContainer = styled.div`    
    display: flex;
    background: #FDFDFD;

    .content {
        display: flex;
        flex-direction: column;
        margin: 20px;    
        width: 100%;
    }

    .error {
        background: #dc3545;
        padding: 10px 20px;

        p {
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;            
            text-align: left;
            margin: 0;
            color: #fff;
        }
    }
`

const StyledTags = styled.div`
    display: grid;
    grid-template-columns: repeat(${({ numberTags }) => numberTags}, auto);
    grid-gap: 0;
    width: max-content;
    margin-bottom: 20px;    

    > div {
        padding: 10px 20px;
        cursor: pointer;

        p {
            font-family: Montserrat;
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0em;
            color: #35374A;
            margin: 0;
        }

        &.active {
            background-color:${pColor};

            p {
                color: #ffffff;
            }            

            &:hover {
                p {
                    color: #ffffff;                
                }
            }
        }

        &:hover {
            p {
                color: ${pColor};                
            }
        }
    }

    @media (max-width: 850px) {
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
    }
`

const GalleryPhotos = () => {
    const { openAlert } = useAlert();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [tags, setTags] = useState([]);

    useEffect(() => {
        galleryPhotosAll()
    }, []);

    const galleryPhotosAll = () => {
        galleryPhotosAllAction()
            .then((res) => {
                if (res.tags) {
                    setTags(handlerDataTags([{ name: 'Todas', slug: 'todas' }, ...res.tags]))
                }
                if (res.photos) {
                    setData(res.photos)
                }
                setLoading(false)
            })
            .catch(() => {
                openAlert("error", "Galeria de fotos", "Error cargando la galería de fotos");
                setLoading(false)
            })
    }

    const handlerDataTags = (tagData) => {
        return tagData.map((tag) => {
            return {
                ...tag,
                active: tag.slug === 'todas' ? true : false
            }
        })
    }

    const selectTag = (slug) => {
        setLoading(true)
        if (slug === 'todas') {
            galleryPhotosAll()
        } else {
            galleryPhotosSlugAction(slug)
                .then((res) => {
                    updateDataTags(slug)
                    setData(res.photos)
                    setLoading(false)
                })
                .catch(() => {
                    openAlert("error", "Galeria de fotos", `Error cargando la galería de fotos ${slug}`);
                    setData(null)
                    setLoading(false)
                })
        }
    }

    const updateDataTags = (slug) => {
        const newTags = tags.map((tag) => {
            if (tag.slug === slug) {
                return {
                    ...tag,
                    active: true
                }
            } else {
                return {
                    ...tag,
                    active: false
                }
            }
        })
        setTags(newTags)
    }

    return (
        <Layout>
            <Title text={<span>Galería de <b>fotos</b></span>} />
            <StyledContainer>
                {
                    loading ?
                        <Loading />
                        :
                        <div className='content'>
                            {
                                tags.length ?
                                    <StyledTags numberTags={tags && tags.length ? tags.length : 0}>
                                        {
                                            tags.map((tag, key) => (
                                                <div
                                                    key={key}
                                                    className={tag.active ? 'active' : ''}
                                                    onClick={() => selectTag(tag.slug)}
                                                >
                                                    <p>{tag.name}</p>
                                                </div>
                                            ))
                                        }
                                    </StyledTags>
                                    :
                                    null
                            }
                            {
                                data && data.length ?
                                    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1200: 4 }}>
                                        <Masonry gutter='10px'>
                                            {
                                                data.map((image, key) => (
                                                    <img src={image.url} alt={`img-${key}`} key={key} />
                                                ))
                                            }
                                        </Masonry>
                                    </ResponsiveMasonry>
                                    :
                                    <div className='error'>
                                        <p>¡No existen fotos asociadas a este tag!</p>
                                    </div>
                            }
                        </div>
                }
            </StyledContainer>
        </Layout>
    )
}

export default GalleryPhotos