import React, { useEffect, useState, useCallback } from "react";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";

//libs
import { getToken } from "../../libs/cookies";
import { oneSignal } from "../../libs/helpers/methods";
import { permissionsMenu } from "./dataMenu";

//context
import { UseUser } from "../../hooks/context/user";
import { useNotification } from "../../hooks/context/notifaction";

// styled notification
import "react-toastify/dist/ReactToastify.css";

//components
import Header from "../header";
import Sidebar from "../sidebar";
import Loading from "../loading";
import AlertNotification from "../alertNotification";
import Footer from "../footer";

//styles
import {
  StyledLayoutContainer,
  StyledBodyContainer,
  StyledHiddenSidebar,
  StyledMainContainer,
} from "./styles";

const windowWidth = () => {
  if (window.innerWidth <= 700) {
    return "phone";
  } else {
    return "desktop";
  }
};

function Layout(props) {
  const { location, history } = props
  const [menu, setMenu] = useState("desktop-menu-open");
  const [auth, setAuth] = useState(null);
  const { user, getMe, getMenu, checkToken } = UseUser();
  const { getNewsNotifications } = useNotification();
  const { loggedNow } = user;
  const token = getToken();

  // const renders = React.useRef(0)

  useEffect(() => {
    checkUser();
    getMe(token);
    getMenu(token);
    getNewsNotifications(token);
    // al inicio de la aplicacion
    if (windowWidth() === "phone") setMenu("phone-menu-close");

    const handleResize = () => {
      //listener
      if (windowWidth() === "phone") {
        setMenu("phone-menu-close");
      } else {
        setMenu("desktop-menu-open");
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(user.menu).length) {
      if (!permissions()) {
        history.push('/401')
      }
    }
  }, [user.menu]);

  const checkUser = () => {
    if (!loggedNow) {
      if (token) {
        checkToken(token)
          .then((res) => {
            setAuth(true);
            oneSignal();
          })
          .catch((err) => {
            setAuth(false);
          });
      } else {
        setAuth(false);
      }
    } else {
      oneSignal();
      setAuth(true);
    }
  };

  const actionMenu = useCallback((value) => {
    if (windowWidth() === "desktop") {
      setMenu(
        value === "desktop-menu-open"
          ? "desktop-menu-close"
          : "desktop-menu-open"
      );
    } else {
      setMenu(
        value === "phone-menu-open" ? "phone-menu-close" : "phone-menu-open"
      );
    }
  }, []);

  const searchPermissions = (data) => {
    if (user.menu[data.menu] instanceof Array) {
      let searchArray = user.menu[data.menu].filter(item => item === data.pos)      
      if (searchArray.length) {
        return searchArray
      }
      return null
    }
    return user.menu[data.menu][data.pos] ? user.menu[data.menu][data.pos] : null
  }


  const permissions = () => {
    let menuFind = permissionsMenu[location.pathname]    
    return (user && user.menu[menuFind.menu]) ?
      typeof menuFind.pos !== 'undefined' ?
        searchPermissions(menuFind) :
        user.menu[menuFind.menu] :
      null
  }

  if (auth === null) {
    return <Loading />;
  } else if (auth) {
    return (
      permissions() ?
        <StyledLayoutContainer>
          <AlertNotification />
          <Header actionMenu={actionMenu} menu={menu} />
          <StyledBodyContainer>
            <Sidebar menu={menu} />
            <StyledHiddenSidebar className={menu} />
            <StyledMainContainer>{props.children}</StyledMainContainer>
          </StyledBodyContainer>
          <Footer menu={menu} />
        </StyledLayoutContainer>
        :
        null
    );
  } else {
    return <Redirect to="/login" />;
  }
}

export default React.memo(withRouter(Layout));
