import React from 'react'
//COMPONENTS
import IcontLinkedin from '../icons/Linkedin'
import IcontFacebook from '../icons/Facebook'
import IcontTwitter from '../icons/Twitter'
import IcontInstagram from '../icons/Instagram'
import IconYoutube from '../icons/Youtube'
import IconTikTok from '../icons/TikTok'
//STYLES
import { StyledFooter } from './styles'

const Footer = ({ menu }) => {    
    return (
        <StyledFooter menu={menu === 'desktop-menu-open'}>
            <IcontLinkedin />
            <IcontInstagram />
            <IcontFacebook />
            <IcontTwitter />
            <IconTikTok />
            <IconYoutube />
        </StyledFooter>
    )
}

export default Footer