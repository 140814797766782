import React from 'react'

const Entrepreneurship = () => {
    return (
        <>
            <h2>
                Si lo que estás buscando es un ecosistema donde iniciar y hacer crecer <b>tu propio negocio,</b> lo has encontrado.
            </h2>
            <h2 className='top bottom'>
                <b>En Grocasa guiaremos tu proyecto de emprendimiento</b> dentro  de la franquicia para que obtengas todos <b>los
                    beneficios de trabajar con el respaldo de una gran marca.</b>
            </h2>
            <h2 className='top bottom'>
                Finaliza tu carrera como asesor y emprende como franquiciado.
            </h2>
            <h2>¿Quieres más información? Escríbenos;</h2>
            <a href='mailto:holafranquiciado@grocasa.com'>holafranquiciado@grocasa.com</a>
        </>
    )
}

export default Entrepreneurship