import React from 'react'
//COMPONENTS
import Layout from '../../components/layout'
import Title from '../../components/ui/title'
//STYLES
import styled from 'styled-components'
import Button from '../../components/ui/button'

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 30px;
    padding: 20px;
    background: #FDFDFD;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);

    img {
        max-width: 100%;
    }

    .buttons {
        display: flex;
        justify-content: space-between;
    }

    a {
        color: #fff;
        text-decoration: none;
        font-weight: 600;
    }

    @media (max-width: 1024px) {
        width: 100%;
    }

    @media (max-width: 600px) {
        .buttons {
            flex-direction: column;
            gap: 15px;

            button {
                width: 100%;
            }
        }
    }
`

const Porfolio = () => {

    return (
        <Layout>
            <Title text={<span><b>Portfolio</b></span>} />
            <StyledContainer>
                <img src="/static/tools/porfolio/porfolio.webp" alt='porfolio' />
                <div className='buttons'>
                    <Button>
                        <a href='/static/tools/porfolio/dosier-ventas.pdf' target='_blank'>
                            Ver online
                        </a>
                    </Button>
                    <Button>
                        <a href='/static/tools/porfolio/dosier-ventas.pdf' download>
                            Descargar PDF
                        </a>
                    </Button>
                </div>
            </StyledContainer>
        </Layout>
    )
}

export default Porfolio