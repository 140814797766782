import React from 'react'
//COMPONENTS
import Layout from '../../components/layout'
import Title from '../../components/ui/title'
//STYLES
import styled from "styled-components";

const StyledContainer = styled.div`    
    img {
        max-width: 100%;
        width: 100%;
    }   
    
    p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #35374A;
        text-align: center;
        margin: 20px 0;

    }
`

const GroChampions = () => {
    return (
        <Layout>
            <Title text={<span><b>Grochampions</b></span>} />
            <StyledContainer>
                <p>
                    Solo las 16 mejores oficinas clasificadas durante Enero-Marzo de 2023 son las que tienen el honor de
                    jugar en la liga de las estrellas. Mes a mes, y según el sistema de clasificación (puntos=firmas)
                    avanzaremos hasta la convención 2024 donde conoceremos cuál será la oficina ganadora.
                </p>
                <img src="/static/grochampions.webp" alt='grochampions' />
            </StyledContainer>
        </Layout>
    )
}

export default GroChampions