import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
//components
import Login from "./pages/login";
import Home from "./pages/home";
import Profile from "./pages/profile";
import RecoveryPassword from "./pages/recoveryPassword";
import ResetPassword from "./pages/resetPassword";
import Dispatcher from "./pages/dispatcher";
import Lead from "./pages/lead";
import Casafari from "./pages/casafari";
import NotFound from "./pages/errorPages/404";
import NotAuthorized from "./pages/errorPages/401";
import ErrorServer from "./pages/errorPages/500";
import Notification from "./pages/notification";
import HearBeat from "./pages/hearbeat";
import League from "./pages/groGames/league";
import Modal from "./components/modal/index"
import GroAgenda from "./pages/groagenda";
import GroChampions from "./pages/grochampions";
import ContactoSede from "./pages/contacto-sede";
import CareerPlan from "./pages/career-plan";
import DressCode from "./pages/dress-code";
import TrainingPlan from "./pages/training-plan";
import AwardCatalog from "./pages/award-catalog";
import Benefits from "./pages/benefits";
import GalleryPhotos from "./pages/gallery-photos";
import Rrhh from "./pages/staff/Rrhh";
import ItCrm from "./pages/staff/ItCrm";
import MarketingDesign from "./pages/staff/MarketingDesign";
import Formation from "./pages/staff/Formation";
import Mentoring from "./pages/staff/Mentoring";
import OfficeImage from "./pages/staff/OfficeImage";
import Legal from "./pages/staff/Legal";
import ContentRRSS from "./pages/tools/ContentRRSS";
import CustomerLetters from "./pages/tools/CustomerLetters";
import Porfolio from "./pages/tools/Porfolio";
import DocsOfInterest from "./pages/tools/DocsOfInterest";
//context
import { UserProvider } from "./hooks/context/user";
import { NotificationProvider } from "./hooks/context/notifaction";
import { AlertProvider } from "./hooks/context/alert";
//hooks
import { useModal } from "./hooks/context/modal";
// import { pushDataGAInit } from "./analytics/dataLeyer";

function App() {
  const { open } = useModal()
  
  return (
    <>      
      <UserProvider>
        <NotificationProvider>
          <AlertProvider>
            <BrowserRouter>
              {open && <Modal />}
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/notification" component={Notification} />
                <Route
                  exact
                  path="/recovery_password"
                  component={RecoveryPassword}
                />
                <Route exact path="/grogames/leagues" component={League} />
                <Route exact path="/grogames/grochampions" component={GroChampions} />
                <Route exact path="/grogames/catalogo-premios" component={AwardCatalog} />
                <Route
                  exact
                  path="/password_reset/:token"
                  component={ResetPassword}
                />
                <Route exact path="/profile" component={Profile} />
                <Route exact path="/leads" component={Lead} />
                <Route exact path="/groagenda" component={GroAgenda} />
                <Route exact path="/documentos/beneficios" component={Benefits} />
                <Route exact path="/documentos/plan-carrera" component={CareerPlan} />
                <Route exact path="/documentos/codigo-vestimenta" component={DressCode} />
                <Route exact path="/documentos/plan-formacion" component={TrainingPlan} />
                <Route exact path="/staff/rrhh" component={Rrhh} />
                <Route exact path="/staff/it-crm" component={ItCrm} />
                <Route exact path="/staff/marketing-design" component={MarketingDesign} />
                <Route exact path="/staff/formation" component={Formation} />
                <Route exact path="/staff/mentoring" component={Mentoring} />
                <Route exact path="/staff/office-image" component={OfficeImage} />
                <Route exact path="/mis-herramientas/contenido-rrss" component={ContentRRSS} />
                <Route exact path="/mis-herramientas/cartas-clientes" component={CustomerLetters} />
                <Route exact path="/mis-herramientas/portfolio" component={Porfolio} />
                <Route exact path="/mis-herramientas/documentos-interes" component={DocsOfInterest} />
                <Route exact path="/staff/legal" component={Legal} />
                <Route exact path="/contactos-sede" component={ContactoSede} />
                <Route exact path="/galeria-fotos" component={GalleryPhotos} />
                <Route exact path="/casafari" component={Casafari} />
                <Route exact path="/hearbeat" component={HearBeat} />
                <Route exact path="/dispatcher/:type" component={Dispatcher} />
                <Route exact path="/500" component={ErrorServer} />
                <Route exact path="/401" component={NotAuthorized} />
                <Route component={NotFound} />
              </Switch>
            </BrowserRouter>

          </AlertProvider>
        </NotificationProvider>
      </UserProvider>
    </>
  );
}

export default App;
