import React, { useState, useEffect } from "react";
import classNames from "classnames";

import { ratingTeam, ratingUser } from "../../actions";

import Card from "../../components/card";


const RatingCard = ({ type, fullData }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  async function getTeams() {
    setLoading(true);
    const res = await ratingTeam(fullData);
    if (res) {
      if (res.meta.success) {
        setData(res.data.teams);
      }
    }
    setLoading(false);
  }

  async function getUsers() {
    setLoading(true);
    const res = await ratingUser(fullData);
    if (res) {
      if (res.meta.success) {
        setData(res.data.users);
      }
    }
    setLoading(false);
  }
  useEffect(() => {
    if (type === "properties.offline.teams") {
      getTeams();
    } else {
      getUsers();
    }
  }, [type, fullData]);

  function activeUserOrTeam(value) {
    if (type === "properties.offline.teams") {
      return value.user_team_position;
    } else {
      return value.user_position;
    }
  }

  function formatPoints(value) {
    const data = value.split(",");
    if (data[1]) {
      return data[1].length >= 2 ? value : `${data[0]},${data[1]}0`;
    }
    return data[0] + ",00";
  }

  return (
    <>
      <Card
        img={
          type === "properties.offline.teams"
            ? `/static/leagues/teams.webp`
            : `/static/leagues/users.webp`
        }
        background={
          type === "properties.offline.teams"
            ? `/static/leagues/background-teams.webp`
            : `/static/leagues/background-users.webp`
        }
        textHeader={
          type === "properties.offline.teams"
            ? "Carrera anual donde todas las oficinas compiten entre ellas. Los puntos se extraen de las firmas confirmadas en el PAD y con reserva a partir del 1 de Enero de 2023."
            : "Carrera anual donde todos/as compiten contra todos/as. Los puntos se extraen de las firmas confirmadas en el PAD y con reserva a partir del 1 de Enero de 2023."
        }
        fullWeight
        footer
        type={type}
      >
        <div className="rating-card-container">
          {loading ? (
            <Loading />
          ) : data.length > 0 ? (
            <>
              <div className="rating-card-all-container">
                {data.slice(0, 3).map((item, index) => {
                  return (
                    <div key={index} className="rating-card-content">
                      <div
                        className={classNames("rating-card-body-content", {})}
                      >
                        <div className="text-content">
                          <div>
                            <div>
                              <h1>{item.position}</h1>
                            </div>
                            <div>
                              <img
                                src={
                                  type === "properties.offline.teams"
                                    ? item.logo
                                    : item.avatar
                                }
                                className="avatar"
                                alt="avatar"
                              />
                            </div>
                            <div>
                              <span>
                                <h2>{item.name}</h2>
                                <p
                                  style={type === "properties.offline.teams" ? { fontWeight: 'bold', fontSize: 14 } : {}}
                                >
                                  {item.agency_name}
                                </p>
                              </span>
                            </div>
                          </div>
                          <h3>
                            {formatPoints(
                              type === "properties.offline.teams"
                                ? item.points
                                : item.billed
                            )}
                          </h3>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="raring-card-users-container">
                {data.map((item, index) => {
                  if (activeUserOrTeam(item)) {
                    return (
                      <div key={index}>
                        <div className="rating-card-content-header">
                          <h3>
                            Tu{" "}
                            {type === "properties.offline.teams"
                              ? "Oficina"
                              : "Posición"}
                          </h3>
                        </div>
                        <div key={index} className="rating-card-content">
                          <div className="rating-card-body-content user-color position">
                            <div className="text-content">
                              <div>
                                <div>
                                  <h1>{item.position}</h1>
                                </div>
                                <div>
                                  <img
                                    src={
                                      type === "properties.offline.teams"
                                        ? item.logo
                                        : item.avatar
                                    }
                                    className="avatar"
                                    alt="avatar"
                                  />
                                </div>
                                <div>
                                  <span>
                                    <h2>{item.name}</h2>
                                    <p>{item.agency_name}</p>
                                  </span>
                                </div>
                              </div>
                              <h3>
                                {formatPoints(
                                  type === "properties.offline.teams"
                                    ? item.points
                                    : item.billed
                                )}
                              </h3>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  }
                })}
              </div>
            </>
          ) : (
            <h3 className="not-data">No hay datos</h3>
          )}
        </div>
      </Card>
    </>
  );
};

const Loading = () => {
  return (
    <svg
      width="100px"
      height="100px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle cx="84" cy="50" r="10" fill="#f3f3f3">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="0.5952380952380952s"
          calcMode="spline"
          keyTimes="0;1"
          values="10;0"
          keySplines="0 0.5 0.5 1"
          begin="0s"
        ></animate>
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="2.380952380952381s"
          calcMode="discrete"
          keyTimes="0;0.25;0.5;0.75;1"
          values="#f3f3f3;#a3a3ac;#b8babd;#d8dddf;#f3f3f3"
          begin="0s"
        ></animate>
      </circle>
      <circle cx="16" cy="50" r="10" fill="#f3f3f3">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="2.380952380952381s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="0s"
        ></animate>
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="2.380952380952381s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="0s"
        ></animate>
      </circle>
      <circle cx="50" cy="50" r="10" fill="#d8dddf">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="2.380952380952381s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.5952380952380952s"
        ></animate>
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="2.380952380952381s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.5952380952380952s"
        ></animate>
      </circle>
      <circle cx="84" cy="50" r="10" fill="#b8babd">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="2.380952380952381s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1.1904761904761905s"
        ></animate>
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="2.380952380952381s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1.1904761904761905s"
        ></animate>
      </circle>
      <circle cx="16" cy="50" r="10" fill="#a3a3ac">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="2.380952380952381s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1.7857142857142856s"
        ></animate>
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="2.380952380952381s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1.7857142857142856s"
        ></animate>
      </circle>
    </svg>
  );
};

export default RatingCard;
