import React from "react";
import styled from "styled-components";
import { pColor, sColor } from "../../styles/variables";

const StyledLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ fullWeight }) => (fullWeight ? "100%" : "fit-content")};
  height: 35px;
  text-decoration: none;
  border: 0;
  font-weight: 500;
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  line-height: 1.5;
  padding: 0 25px;
  font-size: 14px;
  border-radius: 4px;
  height: 36px;
  user-select: none;
  position: relative;
  color: #fff;
  background-color: ${pColor};
  border-color: #fe5000;
  outline: none;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  &:hover {
    background-color: ${sColor};
    text-decoration: none;
    color: #fff;
  }
  margin: ${({ margin }) => margin};
`;

const ButtonLink = ({ href, children, fullWeight, margin, target }) => {
  return (
    <StyledLink
      href={href}
      fullWeight={fullWeight}
      margin={margin}
      target={target}
      download={false}
    >
      {children}
    </StyledLink>
  );
};

export default ButtonLink;
