import React from 'react'

//components
import LinkCustom from '../../components/ui/link'
import Input from '../../components/ui/input'

//styled
import { StyledInputContent, StyledButton, StyledSeparate } from './styled'

const LoginForm = ({ handleOnchange, user, error, errorServer, handleSubmit }) => {
    return (
        <>
            <form>
                <StyledInputContent>
                    <Input
                        error={error.email || errorServer}
                        type="email"
                        name="email"
                        placeholder="Dirección de email"
                        value={user.email}
                        onChange={handleOnchange}
                        fullWidth
                    />
                </StyledInputContent>
                <StyledInputContent>
                    <Input
                        error={error.password || errorServer}
                        type="password"
                        name="password"
                        placeholder="Contraseña"
                        value={user.password}
                        onChange={handleOnchange}
                        fullWidth
                    />
                </StyledInputContent>
                <StyledButton onClick={e => handleSubmit(e)}>Iniciar Sesion</StyledButton>
            </form>
            <StyledSeparate>
                <LinkCustom route="/recovery_password" text="Recuperar Contraseña" leane />
            </StyledSeparate>

        </>
    );
};

export default LoginForm;