import React from 'react'
import { Link } from "react-router-dom";
//COMPONENTS
import Button from '../../../components/ui/button'

const CareerPlan = () => {
    return (
        <>
            <h2>
                En Grocasa tú eres el líder de tu desarrollo:
            </h2>
            <h2 className='top bottom'>
                Nosotros te ayudaremos a conseguirlo gracias  a nuestro plan de carrera ajustado
                a la consecución de tus objetivos.
            </h2>
            <h2>
                Conforme avances, tu paquete retributivo aumentará, pudiendo llegar hasta un 80%
                de incremento en los dos primeros años con nosotros.
            </h2>
            <div className='top'>
                <Link to='/documentos/plan-carrera'>
                    <Button>
                        Conoce el plan de carrera
                    </Button>
                </Link>
            </div>
        </>
    )
}

export default CareerPlan